import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import { IFilters } from './LicensorOverviewPage';

interface IProps {
  submitFn: (e: any) => void;
  filters: IFilters;
  onExportCsv: () => void;
  loading: boolean;
}

export class LicensorOverviewFilters extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);

    this.updateSearch = this.updateSearch.bind(this);
    this.updateMarket = this.updateMarket.bind(this);
  }

  public updateSearch(event: React.ChangeEvent<HTMLInputElement>) {
    this.props.submitFn({
      keyword: event.target.value,
      market: this.props.filters.market,
    });
  }

  public updateMarket(event: React.ChangeEvent<HTMLSelectElement>) {
    this.props.submitFn({
      keyword: this.props.filters.keyword,
      market: event.target.value,
    });
  }

  public render() {
    return (
      <form>
        <div className="row">
          <div className="col-lg-3 col-sm-4 col-xs-12">
            <div className="input-group">
              <input
                type="text"
                className="form-control input-sm"
                value={this.props.filters.keyword}
                onChange={this.updateSearch}
                placeholder="Search"
              />
              <span className="input-group-btn">
                <button className="btn btn-default btn-sm" type="submit">Search</button>
              </span>
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-12">
            <select
              className="form-control input-sm"
              value={this.props.filters.market}
              onChange={this.updateMarket}
            >
              <option value="">All Markets</option>
              <option value="Greek">Greek</option>
              <option value="Collegiate">Collegiate</option>
              <option value="Associations">Associations</option>
            </select>
          </div>
          <div className="col-lg-6 col-sm-5 col-xs-12" style={{ textAlign: 'right', paddingTop: '1px' }}>
            <Button
              bsStyle="default"
              bsSize="sm"
              onClick={this.props.onExportCsv}
              disabled={this.props.loading}
              className="hidden-xs"
            >
              <FontAwesomeIcon className="text-primary" icon={faDownload} /> Export
            </Button>
            <Button
              bsStyle="default"
              bsSize="sm"
              onClick={this.props.onExportCsv}
              disabled={this.props.loading}
              className="visible-xs btn-block"
              style={{ marginTop: '10px' }}
            >
              <FontAwesomeIcon className="text-primary" icon={faDownload} /> Export
            </Button>
          </div>
        </div>
      </form>
    );
  }
}
