import { faDownload, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosResponse } from 'axios';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { Filters, ProductCategory, Vendor } from '../../shared';
import { addHttp, formatRoyaltyRate, removeHttpWww } from '../../utils';
import { FullContent } from '../ContentFrame';
import { FilterBar, LoadingSpinner, PaginationFooter, SortableHeader } from '../shared';
import { ProductCategoryTypeahead } from '../shared';
import { GatewayModal, ModalType } from '../shared';

interface RouteParams {
  licensorId?: string;
}

export const ClientVendorList = (): JSX.Element => {
  const user = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const { licensorId } = useParams<RouteParams>();

  const queryParams = queryString.parse(location.search);

  const [filters, setFilters] = useState<Filters>(() => {
    const initialFilters = new Filters();
    initialFilters.sortBy = 'short_name';
    initialFilters.page = queryParams.page ? Number(queryParams.page) : 1;
    initialFilters.search = queryParams.search ? String(queryParams.search) : '';
    return initialFilters;
  });

  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [loadingVendors, setLoadingVendors] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalVendors, setTotalVendors] = useState<number>(0);
  const [graphTotal, setGraphTotal] = useState<number>(0);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [isInitialLoad, setInitialLoad] = useState<boolean>(true);
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

  const [city, setCity] = useState<string>(
    queryParams.city && typeof queryParams.city === 'string' ? queryParams.city : '',
  );

  const [state, setState] = useState<string | null>(
    queryParams.state && typeof queryParams.state === 'string' ? queryParams.state : null,
  );

  const [productCategory, setProductCategory] = useState<ProductCategory | null>(null);

  const [royaltyScheduleId, setRoyaltyScheduleId] = useState<number | null>(
    queryParams.royalty_schedule_id ? Number(queryParams.royalty_schedule_id) : null,
  );

  const [states, setStates] = useState<{ value: string, label: string }[]>([]);
  const [royaltySchedules, setRoyaltySchedules] = useState<any[]>([]);

  const id = licensorId || (user && user.account ? user.account.id : undefined);
  const fetchStates = () => {
    axios.get('/api/states').then((response: AxiosResponse) => {
      const statesData = response.data.map((stateItem: any) => ({
        value: stateItem.abbr,
        label: stateItem.name,
      }));
      setStates(statesData);
    });
  };

  const fetchRoyaltySchedules = () => {
    axios.get(`/api/clients/${id}/royalty-schedules/active`)
      .then((response: AxiosResponse) => {
        setRoyaltySchedules(response.data);
      })
      .catch((error) => {
        console.error('Error fetching royalty schedules:', error);
      });
  };

  const initialLoad = () => {
    setLoading(true);
    const search = filters.search.length ? `&search=${filters.search}` : '';
    const page = filters.page;
    const cityParam = city ? `&city=${encodeURIComponent(city)}` : '';
    const stateParam = state ? `&state=${encodeURIComponent(state)}` : '';

    const categoryId = queryParams.category_id ||
      (productCategory && productCategory.id && productCategory.name ? productCategory.id : null);
    const categoryParam = categoryId ? `&category_id=${encodeURIComponent(categoryId)}` : '';

    const scheduleParam = royaltyScheduleId ? `&royalty_schedule_id=${encodeURIComponent(royaltyScheduleId)}` : '';

    const list = axios.get(`/api/licensors/${id}/licensed-vendors?page=${page}${search}${cityParam}${stateParam}${categoryParam}${scheduleParam}`);

    list.then((listResponse) => {
      setVendors(listResponse.data.data.map((v: any) => Vendor.fromApi(v)));
      setTotalVendors(listResponse.data.meta.pagination.total);
      setGraphTotal(listResponse.data.meta.pagination.total);
      setLoadingVendors(false);
      setLoading(false);
      setHasNext(filters.page < listResponse.data.meta.pagination.total_pages);
    });

    axios.get(`/api/v2/stats/vendors?organization_id=${id}`).then(() => {
    });
  };

  const getVendorList = () => {
    setLoadingVendors(true);
    setInitialLoad(false);
    const search = filters.search.length ? `&search=${filters.search}` : '';
    const page = filters.page;
    const cityParam = city ? `&city=${encodeURIComponent(city)}` : '';
    const stateParam = state ? `&state=${encodeURIComponent(state)}` : '';

    const categoryParam = productCategory && productCategory.id && productCategory.name
      ? `&category_id=${encodeURIComponent(productCategory.id)}`
      : '';

    const scheduleParam = royaltyScheduleId ? `&royalty_schedule_id=${encodeURIComponent(royaltyScheduleId)}` : '';

    axios.get(`/api/licensors/${id}/licensed-vendors?page=${page}${search}${cityParam}${stateParam}${categoryParam}${scheduleParam}`)
      .then((response) => {
        setVendors(response.data.data.map((v: any) => Vendor.fromApi(v)));
        setLoadingVendors(false);
        setTotalVendors(response.data.meta.pagination.total);
        setGraphTotal(response.data.meta.pagination.total);
        setHasNext(filters.page < response.data.meta.pagination.total_pages);
      });
  };

  const setPage = (page: number) => {
    const newFilters = new Filters();
    Object.assign(newFilters, filters);
    newFilters.page = page;
    setFilters(newFilters);

    const params = queryString.parse(location.search);
    if (page === 1) {
      delete params.page;
    } else {
      params.page = String(page);
    }
    const queryStr = queryString.stringify(params);
    history.replace(`${location.pathname}${queryStr ? `?${queryStr}` : ''}`);
  };

  useEffect(
    () => {
      if (!isInitialLoad) {
        getVendorList();
      }
    },
    [filters.page, isInitialLoad],
  );

  const filter = (params: any, reload: boolean) => {
    const newFilters = new Filters();
    Object.assign(newFilters, filters);
    newFilters.setFilters(params);
    setFilters(newFilters);

    if (reload) {
      const urlParams: any = {
        page: newFilters.page > 1 ? String(newFilters.page) : null,
        search: newFilters.search && newFilters.search.length ? newFilters.search : null,
      };

      const categoryId = queryParams.category_id ||
        (productCategory && productCategory.id && productCategory.name ? productCategory.id : null);

      if (categoryId) urlParams.category_id = categoryId;

      if (royaltyScheduleId) urlParams.royalty_schedule_id = royaltyScheduleId;
      if (city && city.trim() !== '') urlParams.city = city;
      if (state) urlParams.state = state;

      if (newFilters.page === 1) {
        delete urlParams.page;
      }

      const cleaned = omitBy(urlParams, isNil);
      const queryStr = queryString.stringify(cleaned);
      history.replace(`${location.pathname}${queryStr ? `?${queryStr}` : ''}`);

      getVendorList();
    }
  };

  const toggleFiltersModal = () => {
    const willOpen = !showFiltersModal;

    if (willOpen) {
      fetchRoyaltySchedules();
      fetchStates();

      const params = queryString.parse(location.search);

      if (params.category_id) {
        const categoryId = Number(params.category_id);
        if (categoryId) {
          const tempCategory = new ProductCategory();
          tempCategory.id = categoryId;
          tempCategory.name = 'Loading...';
          setProductCategory(tempCategory);

          let isMounted = true;

          axios.get(`/api/product-categories/${categoryId}`)
            .then((response) => {
              if (response.data && isMounted && showFiltersModal) {
                const category = ProductCategory.fromApi(response.data);
                setProductCategory(category);
              }
            })
            .catch((error) => {
              if (isMounted && showFiltersModal) {
                const fallbackCategory = new ProductCategory();
                fallbackCategory.id = categoryId;
                fallbackCategory.name = `Category #${categoryId}`;
                setProductCategory(fallbackCategory);
              }
            });
          setTimeout(() => {
            if (!showFiltersModal) {
              isMounted = false;
            }
          },         0);
        }
      }

      setCity(params.city && typeof params.city === 'string' ? params.city : '');
      setState(params.state && typeof params.state === 'string' ? params.state : null);
      setRoyaltyScheduleId(params.royalty_schedule_id ? Number(params.royalty_schedule_id) : null);
    }

    setShowFiltersModal(willOpen);
  };

  const applyFilters = () => {
    if (royaltyScheduleId && royaltySchedules.length === 0) {
      fetchRoyaltySchedules();
    }

    const params = {
      ...queryString.parse(location.search),
      city: city && city.trim() !== '' ? city : null,
      state: state || null,
      category_id: productCategory ? productCategory.id : null,
      royalty_schedule_id: royaltyScheduleId || null,
      page: null,
    };

    if (!params.search) {
      delete params.search;
    }

    const cleaned = omitBy(params, isNil);

    const queryStr = queryString.stringify(cleaned);
    history.replace(`${location.pathname}${queryStr ? `?${queryStr}` : ''}`);

    setShowFiltersModal(false);
    getVendorList();
  };

  const resetFilters = () => {
    history.replace(location.pathname);

    const newFilters = new Filters();
    newFilters.sortBy = 'short_name';
    newFilters.search = '';
    newFilters.page = 1;

    setFilters(newFilters);
    setProductCategory(null);
    setCity('');
    setState(null);
    setRoyaltyScheduleId(null);
    setShowFiltersModal(false);

    setTimeout(() => {
      getVendorList();
    },         0);
  };

  const handleVendorClick = (vendorId: number) => {
    let url;
    if (user && (user.type === 'admin')) {
      url = `/clients/${id}/vendors/${vendorId}`;
    } else {
      url = `/vendors/${vendorId}`;
    }

    history.push(url);
  };

  useEffect(() => {
    let isMounted = true;

    if (queryParams.category_id) {
      const categoryId = Number(queryParams.category_id);
      if (categoryId) {
        const tempCategory = new ProductCategory();
        tempCategory.id = categoryId;
        tempCategory.name = 'Loading category...';
        setProductCategory(tempCategory);

        axios.get(`/api/product-categories/${categoryId}`)
          .then((response) => {
            if (response.data && isMounted) {
              const category = ProductCategory.fromApi(response.data);
              setProductCategory(category);
            }
          })
          .catch((error) => {
            if (isMounted) {
              const fallbackCategory = new ProductCategory();
              fallbackCategory.id = categoryId;
              fallbackCategory.name = `Category #${categoryId}`;
              setProductCategory(fallbackCategory);
            }
          });
      }
    } else if (!queryParams.category_id && productCategory) {
      setProductCategory(null);
    }

    return () => {
      isMounted = false;
    };
  },        [queryParams.category_id]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    let shouldFetch = false;

    if (params.city && typeof params.city === 'string' && params.city !== city) {
      setCity(params.city);
      shouldFetch = true;
    }

    if (params.state && typeof params.state === 'string' && params.state !== state) {
      setState(params.state);
      shouldFetch = true;
    }

    if (params.royalty_schedule_id && params.royalty_schedule_id !== royaltyScheduleId) {
      setRoyaltyScheduleId(Number(params.royalty_schedule_id));
      shouldFetch = true;
    }

    if (params.category_id) {
      const urlCategoryId = Number(params.category_id);
      const currentCategoryId = productCategory ? productCategory.id : null;

      if (urlCategoryId !== currentCategoryId) {
        const tempCategory = new ProductCategory();
        tempCategory.id = urlCategoryId;
        tempCategory.name = 'Loading...';
        setProductCategory(tempCategory);
        shouldFetch = true;

        axios.get(`/api/product-categories/${urlCategoryId}`).then((response) => {
          if (response.data) {
            setProductCategory(ProductCategory.fromApi(response.data));
          }
        }).catch((error) => {
          const fallbackCategory = new ProductCategory();
          fallbackCategory.id = urlCategoryId;
          fallbackCategory.name = `Category #${urlCategoryId}`;
          setProductCategory(fallbackCategory);
        });
      }
    } else if (params.category_id === undefined && productCategory !== null) {
      setProductCategory(null);
      shouldFetch = true;
    }

    if (isInitialLoad) {
      initialLoad();
    } else if (shouldFetch) {
      getVendorList();
    }
  },        [location.search]);

  const vendorRows = vendors.map((vendor) => {
    return (
      <tr key={vendor.id}>
        <td onClick={() => handleVendorClick(vendor.id)} style={{ cursor: 'pointer' }}>
          <div className="load-account" style={{ zIndex: 2 }} >
            <img className="pull-left hidden-sm hidden-xs" src={vendor.image.getSize('th')} alt={`${vendor.shortName} logo`} />
            <strong>{vendor.shortName}</strong>
          </div>
        </td>
        <td className="text-muted">
          {vendor.city}, {vendor.state}
        </td>
        <td><a target="_blank" href={addHttp(vendor.website)}>{removeHttpWww(vendor.website)}</a></td>
        <td>{vendor.owner ? `${vendor.owner.first_name} ${vendor.owner.last_name}` : ''}</td>
        <td>{vendor.owner ? vendor.owner.email : ''}</td>
        <td>{vendor.owner && vendor.owner.phone ? vendor.owner.phone : vendor.phone}</td>
      </tr>
    );
  });

  let exportLink;
  if (licensorId) {
    exportLink = `/clients/${licensorId}/licenses/csv`;
  } else {
    exportLink = '/licenses/csv';
  }

  const header = (
    <div className="panel-body">
      { totalVendors > 0 ? (
        <h2 style={{ marginBottom: 0 }} className="text-primary text-center">{ graphTotal }</h2>
      ) : null}
      <h3 style={{ margin: 0 }} className="text-center">Licensed Vendors</h3>
      <p className="text-muted text-center">View and export your current licensee list.</p>
    </div>
  );

  if (loading) {
    return (
      <FullContent breadcrumbs={[{ name: 'Vendors' }]}>
        <div className="panel panel-portal">
          {header}
          <div className="panel-body">
            <LoadingSpinner />
          </div>
        </div>
      </FullContent>
    );
  }

  if (!vendors.length && isInitialLoad) {
    return (
      <FullContent breadcrumbs={[{ name: 'Vendors' }]}>
        <div className="panel panel-portal">
          {header}
          <div className="panel-body">
            <h4 style={{ paddingBottom: 20 }} className="text-center strong">
              We'll show your licensee list once you sign and activate your first license agreement.
            </h4>
          </div>
        </div>
      </FullContent>
    );
  }

  return (
    <FullContent breadcrumbs={[{ name: 'Vendors' }]}>
      <div>
        <div className="panel panel-portal">
          {header}
          <div className="panel-body">
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
                <div style={{ flexGrow: 1 }}>
                  <FilterBar
                    useSearch={true}
                    usePerPage={false}
                    useQuarter={false}
                    updateFilters={filter}
                    search={filters.search}
                  />
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  bsStyle="default"
                  bsSize="sm"
                  onClick={toggleFiltersModal}
                  style={{ marginRight: '10px' }}
                >
                  <FontAwesomeIcon icon={faFilter} /> Filters
                </Button>

                <a
                  target="_blank"
                  href={(() => {
                    const params = ['export=true'];

                    if (city && city.trim() !== '') {
                      params.push(`city=${encodeURIComponent(city)}`);
                    }

                    if (state) {
                      params.push(`state=${encodeURIComponent(state)}`);
                    }

                    const categoryId = queryParams.category_id || (productCategory ? productCategory.id : null);
                    if (categoryId) {
                      params.push(`category_id=${encodeURIComponent(categoryId)}`);
                    }

                    if (royaltyScheduleId) {
                      params.push(`royalty_schedule_id=${encodeURIComponent(royaltyScheduleId)}`);
                    }

                    if (filters.search && filters.search.length) {
                      params.push(`search=${encodeURIComponent(filters.search)}`);
                    }

                    return `${exportLink}?${params.join('&')}`;
                  })()}
                  className="btn btn-default btn-sm"
                >
                  <FontAwesomeIcon className="text-primary" icon={faDownload} /> Download Licensee List
                </a>
              </div>
            </div>

            <GatewayModal
              shown={showFiltersModal}
              onClose={toggleFiltersModal}
              type={ModalType.Primary}
              title="Filter Vendors"
            >
              <Modal.Body>
                <FormGroup>
                  <label>Royalty Schedule</label>
                  <div className="form-control-container">
                    <select
                      className="form-control"
                      value={royaltyScheduleId || ''}
                      onChange={e => setRoyaltyScheduleId((e.target as HTMLSelectElement).value ? Number((e.target as HTMLSelectElement).value) : null)}
                    >
                      <option value="">Select Royalty Schedule</option>
                      {royaltySchedules
                        .filter(schedule => schedule.royalty_rate > 0)
                        .map(schedule => (
                          <option
                            key={schedule.id}
                            value={schedule.id}
                          >
                            {formatRoyaltyRate(
                              schedule.title,
                              schedule.royalty_rate,
                              schedule.minimum_royalty_per_unit,
                              schedule.unit_definition,
                            )}
                          </option>
                        ))}
                    </select>
                  </div>
                </FormGroup>

                <FormGroup>
                  <label>Product Category</label>
                  <ProductCategoryTypeahead
                    selected={productCategory ? [productCategory] : []}
                    onChange={(selected: ProductCategory[]) => setProductCategory(selected.length ? selected[0] : null)}
                    multiple={false}
                    key={`category-typeahead-${productCategory ? productCategory.id : 'empty'}-${showFiltersModal ? 'visible' : 'hidden'}`}
                  />
                </FormGroup>

                <FormGroup>
                  <label>City</label>
                  <FormControl
                    type="text"
                    value={city || ''}
                    onChange={e => setCity((e.target as HTMLInputElement).value)}
                    placeholder="City"
                  />
                </FormGroup>

                <FormGroup>
                  <label>State</label>
                  <div className="form-control-container">
                    <select
                      className="form-control"
                      value={state || ''}
                      onChange={e => setState((e.target as HTMLSelectElement).value || null)}
                    >
                      <option value="">Select State</option>
                      {states.map(stateOption => (
                        <option
                          key={stateOption.value}
                          value={stateOption.value}
                        >
                          {stateOption.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </FormGroup>
                <div style={{ marginTop: 15, textAlign: 'center' }}>
                  <a
                    href="#"
                    onClick={(e) => { e.preventDefault(); resetFilters(); }}
                    className="text-primary"
                  >
                    Reset All Filters
                  </a>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button onClick={toggleFiltersModal}>
                    Cancel
                  </Button>
                  <Button bsStyle="primary" onClick={applyFilters}>
                    Filter Results
                  </Button>
                </div>
              </Modal.Footer>
            </GatewayModal>
          </div>
          <div className="panel-body">
            {loadingVendors ? (<LoadingSpinner />) :
              vendors.length ?
              (
                <div className="table-responsive">
                  <table className="table table-portal">
                    <thead>
                      <tr>
                        <SortableHeader
                          title="Vendor"
                          sortDir="desc"
                          sortKey="vendor"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Location"
                          sortDir="desc"
                          sortKey="vendor"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Website"
                          sortDir="desc"
                          sortKey="vendor"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Contact"
                          sortDir="desc"
                          sortKey="contact"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Email"
                          sortDir="desc"
                          sortKey="email"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                        <SortableHeader
                          title="Phone"
                          sortDir="desc"
                          sortKey="phone"
                          sortSelectFn={() => {}}
                          disableSort={true}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {vendorRows}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>
                  <h4 className="text-center text-muted">No Matching Vendors Found.</h4>
                </div>
              )
            }
          </div>
        </div>

        <PaginationFooter
          currentPage={filters.page}
          totalResults={totalVendors}
          setPage={setPage}
          hasNext={hasNext}
        />
      </div>
    </FullContent>
  );
};
