import * as React from 'react';
import ReactQuill from 'react-quill';

export const EnforcementTemplateQuill = (props: { value: string, onChange: (template:string) => void}) => {
  const quill = React.useRef<ReactQuill | null>(null);
  const editorModules = React.useState({
    toolbar: {
      container: '#toolbar',
      handlers: {
        'affinity-template-variable': (v: string) => {

          let text = '';

          switch (v) {
            case 'CASE_OWNER_FULL_NAME':
              text = 'Case Owner Full Name';
              break;
            case 'CASE_OWNER_FIRST_NAME':
              text = 'Case Owner First Name';
              break;
            case 'CASE_OWNER_EMAIL':
              text = 'Case Owner Email';
              break;
            case 'CASE_OWNER_PHONE':
              text = 'Case Owner Phone';
              break;
            case 'CASE_OWNER_TITLE':
              text = 'Case Owner Title';
              break;
            case 'BUSINESS_OWNER_FULL_NAME':
              text = 'Business Owner Full Name';
              break;
            case 'BUSINESS_OWNER_FIRST_NAME':
              text = 'Business Owner First Name';
              break;
            case 'BUSINESS_OWNER_EMAIL':
              text = 'Business Owner Email';
              break;
            case 'BUSINESS_OWNER_TITLE':
              text = 'Business Owner Title';
              break;
            case 'VENDOR_NAME':
              text = 'Vendor Name';
              break;
            case 'TRADEMARK_USE_BY_LICENSOR':
              text = 'Trademark Use By Licensor';
              break;
            case 'UNRESOLVED_WEB_LINKS':
              text = 'Unresolved Web Links';
              break;
            case 'ALL_WEB_LINKS':
              text = 'All Web Links';
              break;
            case 'ALL_WEB_LINKS_CSV':
              text = 'All Web Links CSV';
              break;
            case 'INITIAL_CONTACT':
              text = 'Initial Contact';
              break;
            case 'DATE_LONG':
              text = 'Date (Month DD, YYYY)';
              break;
            case 'DATE_SHORT':
              text = 'Date (MM/DD/YYYY)';
              break;
            case 'VENDOR_ADDRESS_INLINE':
              text = 'Vendor Address Inline';
              break;
            case 'VENDOR_ADDRESS_MULTILINE':
              text = 'Vendor Address Multiline';
              break;
            case 'CASE_ID':
              text = 'Case ID';
              break;
            default:
              text = 'Unknown';
          }

          const data = { text, label: v };

          const current = quill.current;
          if (current) {
            const editor = current.getEditor();
            const selection = editor.getSelection();
            if (selection) {
              editor.insertEmbed(selection.index, 'affinity-template-variable', data, 'user');
              editor.setSelection(selection.index + 1, 0);

            }

          }
        },
      },
    },
    keyboard: {
      bindings: {
        'affinity-templates': {
          collapsed: true,
          key: 'enter',
          shiftKey: true,
          format: ['affinity-templates', 'template-content'],
          handler: (range: any, context: any) => {
            const current = quill.current;
            if (current) {
              const editor = current.getEditor();
              editor.insertText(range.index + 1, '', 'user');
              editor.formatText(range.index, 1, { 'affinity-templates': null });
              editor.setSelection(range.index + 1);

            }

          },
        },
      },
    },

  });
  const { value, onChange } = props;
  return (
    <>
      <div id="toolbar">

        <select className="ql-size" defaultValue={''} onChange={e => e.persist()}>
          <option value="small"></option>
          <option selected></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
        <button value="bullet" className="ql-list" />
        <button value="ordered" className="ql-list" />
        <button value="bold" className="ql-bold" />
        <button value="italic" className="ql-italic" />
        <button value="underline" className="ql-underline" />
        <button value="blockquote" className="ql-blockquote" />
        <button value="link" className="ql-link" />

        <select className="ql-align" defaultValue={''} onChange={e => e.persist()}>
          <option selected></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>

        <select className="ql-affinity-templates">
          <option value="HAS_GREEK_USES">Has Greek Uses</option>
          <option value="HAS_COLLEGE_USES">Has College Uses</option>
          <option value="HAS_ASSOCIATION_USES">Has Association Uses</option>
        </select>
        <select className="ql-affinity-template-variable">
          <option value="CASE_OWNER_FULL_NAME">Case Owner Full Name</option>
          <option value="CASE_OWNER_FIRST_NAME">Case Owner First Name</option>
          <option value="CASE_OWNER_EMAIL">Case Owner Email</option>
          <option value="CASE_OWNER_PHONE">Case Owner Phone</option>
          <option value="CASE_OWNER_TITLE">Case Owner Title</option>

          <option value="BUSINESS_OWNER_FULL_NAME">Business Owner Full Name</option>
          <option value="BUSINESS_OWNER_FIRST_NAME">Business Owner First Name</option>
          <option value="BUSINESS_OWNER_EMAIL">Business Owner Email</option>
          <option value="BUSINESS_OWNER_TITLE">Business Owner Title</option>
          <option value="VENDOR_NAME">Vendor Name</option>
          <option value="TRADEMARK_USE_BY_LICENSOR">Trademark Use by Licensor </option>
          <option value="ALL_WEB_LINKS">All Web Links</option>
          <option value="ALL_WEB_LINKS_CSV">All Web Links CSV</option>
          <option value="UNRESOLVED_WEB_LINKS">Unresolved Web Links</option>
          <option value="INITIAL_CONTACT">Initial Contact</option>
          <option value="DATE_SHORT">Date (MM/DD/YYYY)</option>
          <option value="DATE_LONG">Date (Month DD, YYYY)</option>
          <option value="VENDOR_ADDRESS_INLINE">Vendor Address Inline</option>
          <option value="VENDOR_ADDRESS_MULTILINE">Vendor Address Multiline</option>
          <option value="CASE_ID">Case ID</option>
        </select>
      </div>

      <ReactQuill
        value={value}
        id="enforcement-quill"
        ref={quill}

        modules={editorModules[0]}

        style={{ marginBottom: 15 }}
        onChange={onChange}
      />
    </>

  );

};
