import Axios from 'axios';
import { isEmpty, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router';
import { formattedFiscalYear, getCurrentFiscalYear } from '../../utils';
import { FullContent } from '../ContentFrame';
import { CurrencyLabel, LoadingSpinner, PaginationFooter } from '../shared';
import { PaymentReportsGuide } from './PaymentReportsGuide';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';
import { usePerformanceQueryParams } from './usePerformanceQueryParams';

interface QuarterData {
  current: number;
  previous: number;
  percentDifference: number;
  difference: number;
}
interface VendorPaymentStats {
  rank: number;
  vendor: string;
  id: number;
  image: string;
  quarters: {
    q1: QuarterData
    q2: QuarterData
    q3: QuarterData
    q4: QuarterData,
  };
  annual: QuarterData;
}

export const VendorPaymentPerformancePage = () => {
  const [vendorStats, setVendorStats] = React.useState<VendorPaymentStats[]>([]);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [loading, setLoading] = React.useState(true);
  const [years, setYears] = React.useState<number[]>([]);
  const [search, setSearch] = React.useState('');
  const performanceQP = usePerformanceQueryParams();
  const location = useLocation();
  const history = useHistory();

  React.useEffect(
    () => {
      const qp = queryString.parse(location.search);
      setSearch(qp.search ? String(qp.search) : '');
      const newPage = qp.page ? Number(qp.page) : 1;
      setPage(newPage);
      getData(newPage);
      performanceQP.scrollToTop();
    },
    [location.search],
  );

  const handleSearch = (s: string) => {
    const params = queryString.parse(location.search);
    Object.assign(params, { search: s || null, page: 1 });
    const cleaned = omitBy(params, isEmpty);
    const qs = queryString.stringify(cleaned);
    history.push(`${location.pathname}?${qs}`);
  };

  const getData = async (page: number) => {
    setLoading(true);
    try {
      const filters = queryString.parse(location.search);

      const queryParams = [
        { value: page, label: 'page' },
      ];

      if (filters.search) {
        queryParams.push({ value: String(filters.search), label: 'search' });
      }

      const qs = performanceQP.toApi('vendor-payment', queryParams);

      const response = await Axios.get(`/api/performance-reports/vendor-payments?${qs}`);
      const vendors = response.data.vendors;

      const v: VendorPaymentStats[] = vendors.map((d: any, index: number) => {
        return {
          rank: page > 1 ? ((page - 1) * 10) + index + 1 : (index + 1),
          vendor: d.vendorName,
          id: d.vendorId,
          image: d.image,
          quarters: {
            q1: d.quarters['1'],
            q2: d.quarters['2'],
            q3: d.quarters['3'],
            q4: d.quarters['4'],
          },
          annual: d.annual,
        };
      });

      const availableYears = response.data.availableYears.map((y: any) => Number(y));
      if (!availableYears.includes(Number(response.data.latest.year))) {
        availableYears.push(Number(response.data.latest.year));
      }
      setYears(availableYears.sort());
      setLastPage(response.data.maxPage);
      setVendorStats(v);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getAnnualComparision = (stats: VendorPaymentStats) => {
    const currentYear = performanceQP.params.year;
    if (
      (stats.quarters.q1.current === 0 ||
        stats.quarters.q2.current === 0 ||
        stats.quarters.q3.current === 0 ||
        stats.quarters.q4.current === 0) &&
      currentYear === getCurrentFiscalYear()
    ) {
      return '—';
    }
    return getComparisions(stats.annual.percentDifference, stats.annual.difference, stats.annual.previous);
  };

  const getComparisions = (percent: number, value: number, previousValue: number) => {
    if (value === 0 || percent === -100 || previousValue === 0) {
      return <div className="small">—</div>;
    }
    return (
      <div className={`small ${percent > 0 ? 'text-success' : 'text-danger' }`}>
        <NumberFormat decimalScale={0} suffix="%" displayType="text" value={percent} /> (<CurrencyLabel value={value} />)
      </div>
    );
  };

  const rows = vendorStats.map(v => (
    <tr key={v.rank}>
      <td style={{ verticalAlign: 'middle' }} className="text-right text-muted">
        {v.rank}.
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img style={{ marginRight: 10, height: 25, width: 25 }} src={v.image} className="img-responsive" />
          <strong>{v.vendor}</strong>
        </div>
      </td>
      <td className="text-center">
        {v.quarters.q1.current ? <CurrencyLabel value={v.quarters.q1.current} /> : '—'}
        <div className="text-muted small">
          LY <CurrencyLabel value={v.quarters.q1.previous} />
        </div>
        {getComparisions(v.quarters.q1.percentDifference, v.quarters.q1.difference, v.quarters.q1.previous)}
      </td>
      <td className="text-center">
        {v.quarters.q2.current ? <CurrencyLabel value={v.quarters.q2.current} /> : '—'}
        <div className="text-muted small">
          LY <CurrencyLabel value={v.quarters.q2.previous} />
        </div>
        {getComparisions(v.quarters.q2.percentDifference, v.quarters.q2.difference, v.quarters.q2.previous)}
      </td>
      <td className="text-center">
        {v.quarters.q3.current ? <CurrencyLabel value={v.quarters.q3.current} /> : '—'}
        <div className="text-muted small">
          LY <CurrencyLabel value={v.quarters.q3.previous} />
        </div>
        {getComparisions(v.quarters.q3.percentDifference, v.quarters.q3.difference, v.quarters.q3.previous)}
      </td>
      <td className="text-center">
        {v.quarters.q4.current ? <CurrencyLabel value={v.quarters.q4.current} /> : '—'}
        <div className="text-muted small">
          LY <CurrencyLabel value={v.quarters.q4.previous} />
        </div>
        {getComparisions(v.quarters.q4.percentDifference, v.quarters.q4.difference, v.quarters.q4.previous)}
      </td>
      <td className="text-center">
        <CurrencyLabel value={v.annual.current} />
        <div className="text-muted small">
          LY <CurrencyLabel value={v.annual.previous} />
        </div>
        {getAnnualComparision(v)}
      </td>
    </tr>
  ));

  const exportLink = performanceQP.params.licensorId ?
    `/clients/${performanceQP.params.licensorId}/reports/vendors/csv?year=${performanceQP.params.year}` :
    `/reports/vendors/csv?year=${performanceQP.params.year}`;

  return (
    <FullContent>
      <PerformanceTitleFilters
        isAggregate={false}
        isCurrent={false}
        title="Licensee Payments"
        subTitle="Review gross payments received from licensees."
        hideQuarter={true}
        hideYear={true}
        hideStartEndDate={true}
        hideFilters={false}
        yearsOverride={years}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <form
              style={{ marginRight: 15, marginBottom: 0 }}
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch(search);
              }}
            >
              <div className="form-group" style={{ width: 300, marginBottom: 0 }}>
                <input
                  type="text"
                  className="form-control input-sm"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>
            </form>
            <DropdownButton
              style={{ marginRight: '10px' }}
              id="year-dropdown"
              bsStyle="default"
              bsSize="sm"
              title={performanceQP.params.year ? formattedFiscalYear(performanceQP.params.year) : (years.length > 0 ? formattedFiscalYear(years[years.length - 1]) : '')}
              onSelect={(fiscalYear: string) => {
                if (fiscalYear === formattedFiscalYear(performanceQP.params.year)) return;
                const params = queryString.parse(location.search);
                Object.assign(params, { year: fiscalYear, page: 1 });
                const cleaned = omitBy(params, isEmpty);
                const qs = queryString.stringify(cleaned);
                history.push(`${location.pathname}?${qs}`);
              }}
            >
              {years.map(year => (
                <MenuItem key={year} eventKey={formattedFiscalYear(year)}>
                  {formattedFiscalYear(year)}
                </MenuItem>
              ))}
            </DropdownButton>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <a target="_blank" href={exportLink} className="btn btn-default btn-sm">Export</a>
          </div>
        </div>
      </PerformanceTitleFilters>

      {loading ? <div className="panel panel-portal">
        <div className="panel-body">
          <LoadingSpinner />
        </div>
      </div> : <div className="panel panel-portal">
        <div className="table-responsive">
          <table className="table">
            <thead>
            <tr>
              <th className="text-center"></th>
              <th className="text-center"></th>
              <th className="text-center">
                Q1
                <div className="small text-muted">Jul - Sep</div>
              </th>
              <th className="text-center">
                Q2
                <div className="small text-muted">Oct - Dec</div>
              </th>
              <th className="text-center">
                Q3
                <div className="small text-muted">Jan - Mar</div>
              </th>
              <th className="text-center">
                Q4
                <div className="small text-muted">Apr - Jun</div>
              </th>
              <th className="text-center">
                Annual
                <div className="small text-muted">Jul - Jun</div>
              </th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>}
      <PaginationFooter
        hasNext={page < lastPage}
        setPage={(newPage) => {
          const params = queryString.parse(location.search);
          Object.assign(params, { page: newPage });
          const cleaned = omitBy(params, isEmpty);
          const qs = queryString.stringify(cleaned);
          history.push(`${location.pathname}?${qs}`);
        }}
        currentPage={page}
      />
      <PaymentReportsGuide />
    </FullContent>
  );
};
