import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import { createNoteThread, CreateThreadRequest } from '../../../api';
import { UserContext } from '../../../contexts';
import { EnforcementTemplate } from '../../../shared/EnforcementTemplate';
import { WebBotUrlStatuses } from '../../../shared/WebBotUrl';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { WebBotUrlFilters } from '../WebBotUrlFilters';

export const EnforcmentTemplateModal = (props: { shown: boolean, onClose: () => void }) => {
  const { licensorId, vendorId } = useParams<{licensorId?: string, vendorId?: string}>();
  const [templates, setTemplates] = React.useState<EnforcementTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = React.useState<EnforcementTemplate>();
  const [templatePreview, setTemplatePreview] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [prevPlatform, setPrevPlatform] = React.useState('');
  const location = useLocation();
  const user = React.useContext(UserContext);
  const { shown, onClose } = props;
  const filters = WebBotUrlFilters.getInitialFilters(location.search);

  React.useEffect(() => {
    if (filters.platform.toString() !== prevPlatform) {
      setPrevPlatform(filters.platform.toString());
      getTemplates();
    }
  },              [filters.platform]);

  const close = () => {
    onClose();
    setSelectedTemplate(undefined);
    setTemplatePreview('');
  };

  const getTemplates = async () => {
    setLoading(true);
    const filters = WebBotUrlFilters.getInitialFilters(location.search);
    let query = '';
    if (filters.platform && filters.platform !== 0) {
      query = `?platform_id=${filters.platform}`;
    }
    const res = await fetch(`/api/enforcement-templates${query}`);
    const data = await res.json();
    setTemplates(data.data.map((d: any) => EnforcementTemplate.fromApi(d)));
    setLoading(false);
  };

  const getTemplatePreview = async (id: number) => {
    setLoading(true);
    try {
      let accId;
      if (licensorId) {
        accId = licensorId;
      }
      if (vendorId) {
        accId = vendorId;
      }
      const res = await fetch(`/api/enforcement-template-previews?template_id=${id}${accId ? `&account_id=${accId}` : ''}${filters.status ? `&status=${filters.status}` : ''}${filters.platform ? `&platform=${filters.platform}` : ''}`);
      const data = await res.text();
      setTemplatePreview(data);
    } catch (error) {
      setTemplatePreview('<div class="text-danger">Error loading template preview</div>');
    }
    setLoading(false);
  };

  const submitDmcaEmail = async () => {
    setSubmitting(true);
    let accId;
    if (licensorId) {
      accId = licensorId;
    }
    if (vendorId) {
      accId = vendorId;
    }
    await fetch('/api/dmca-emails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ account_id: accId, template_id: selectedTemplate && selectedTemplate.id, status: filters.status || WebBotUrlStatuses.NeedsTakedown, platform: filters.platform || undefined }),
    });
    close();
    setSubmitting(false);
  };

  const createNote = async () => {
    if (selectedTemplate) {
      setSubmitting(true);
      let accId;
      if (licensorId) {
        accId = licensorId;
      }
      if (vendorId) {
        accId = vendorId;
      }
      const res = await fetch(`/api/enforcement-template-previews/${selectedTemplate.id}/create-note?account_id=${accId}${filters.status ? `&status=${filters.status}` : ''}`, { method: 'POST' });
      const data = res.json();

      close();
      setSubmitting(false);
    }
  };

  const copyTemplateToClipboard = () => {
    const element = document.getElementById('template-content-area');
    if (element) {
      const range = document.createRange();
      range.selectNode(element);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
      }
    }
  };

  return (
    <GatewayModal backdrop="static" shown={shown} type={ModalType.Primary} onClose={close} title="Enforcement Templates" size="large">
      <Modal.Body>
        <p className="text-muted">Select Template to generate preview</p>
        <div className="form-group">
          <label>Template</label>
          {loading && templates.length === 0 ? (
            <div className="text-center" style={{ minHeight: '34px' }}><LoadingSpinner /></div>
          ) : (
              <select
                onChange={(e) => {
                  const t = templates[e.target.value];
                  setSelectedTemplate(t);
                  getTemplatePreview(t.id);
                }}
                className="form-control"
                disabled={loading}
                defaultValue=""
              >
                <option value="" disabled>
                  {templates.length === 0 ? 'No templates available' : 'Select Template'}
                </option>
                {templates.map((t, index) => (
                  <option key={t.id} value={index}>
                    {t.name}
                  </option>
                ))}
              </select>
            )}
        </div>
        <hr />
        {loading ? (
          <LoadingSpinner />
        ) : (
            <div style={{ height: 200, overflow: 'scroll', backgroundColor: 'transparent', resize: 'vertical' }} className="well">
              <div id="template-content-area" dangerouslySetInnerHTML={{ __html: templatePreview }} />
            </div>
          )}
        {templatePreview ? (
          <div>
            <button style={{ marginBottom: 10 }} onClick={copyTemplateToClipboard} className="btn btn-block btn-default">
              Copy Template
            </button>
            {selectedTemplate && selectedTemplate.dmcaType !== 0 ? (
              <div className="row">
                <div className="col-md-6">
                  <a
                    href={(selectedTemplate && selectedTemplate.platform && selectedTemplate.platform.dmca && selectedTemplate.platform.dmca.formUrl) || ''}
                    target="_blank"
                    className="btn btn-block btn-default"
                  >
                    View Web Form
                  </a>
                </div>
                <div className="col-md-6">
                  <button onClick={submitDmcaEmail} className="btn btn-block btn-primary">
                    {submitting ? 'Sending...' : 'Send Platform Notice'}
                  </button>
                </div>
              </div>
            ) : (
                <button onClick={createNote} className="btn btn-block btn-primary">
                  {submitting ? 'Saving...' : 'Save Account Note'}
                </button>
              )}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <button onClick={close} className="btn btn-default pull-left">
          Cancel
        </button>
      </Modal.Footer>
    </GatewayModal>
  );

};
